<template>
<v-container v-if="show" fluid fill-height>
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Історія товарного обігу</h1>
        </v-card-title>

        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="confirmedFalse">
            Немає доступу до інформації. Ваші контактні дані не підтверджені менеджером.
        </v-alert>
        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="contractorsFalse">
            Немає жодного доступного контрагента для перегляду iсторії товарного обігу.
        </v-alert>
        <template v-if="!confirmedFalse && !contractorsFalse">
            <v-card class="mb-4 blue lighten-5 px-0 px-md-2" flat>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" class="pb-0">
                            <h4>Дата продажу:</h4>
                            <v-row>
                                <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                    <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedSellDateFrom" label="з" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="sellDateFrom" @input="menu1 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6" lg="4" class="d-flex align-center">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="formatedSellDateTo" label="по" prepend-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker first-day-of-week="1" color="primary" header-color="primary" locale="uk-ua" v-model="sellDateTo" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <div class="mb-6">
                                <h4 class="mt-4">Контрагент:</h4>
                                <v-checkbox hide-details class="shrink mr-2" v-for="item in contractors" v-model="contractor[item.typhoon_id]" :label="item.name" :key="item.id"></v-checkbox>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="4" class="pt-0">
                            <v-select :items="categories" clearable v-model="category" label="Категория"></v-select>
                            <v-text-field v-model="query" label="Пошук товару по: SN, Код, Назва, Артикул, Номер документа"></v-text-field>
                            <div>
                                <h4 class="mt-4">Результат деталізувати до:</h4>
                                <v-checkbox hide-details class="shrink mr-2" v-model="groupBySn" label="SN"></v-checkbox>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="mt-4">
                    <v-btn class="primary" @click="loadList()">Показати</v-btn>
                    <v-btn @click="clear()">Очистити</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="d-flex align-center body-2 px-0 my-2" flat>
                <v-btn text :loading="xlsLoading" :disabled="totalItems > 0 ? false : true" @click="createXlsx(list)">
                    <span class="mr-2">
                        <v-icon color="green darken-3">{{exclelIcon}}</v-icon>
                    </span>
                    <span>Завантажити</span>
                </v-btn>
                <v-spacer></v-spacer>
                <div>
                    Знайдено: {{totalItems}}
                </div>
            </v-card>
            <v-data-table :search="search" multi-sort :loading="listLoading" :headers="filteredHeaders" class="elevation-1 products" :items="list" :footer-props="footerProps" :options.sync="options" mobile-breakpoint="0">
                <template v-slot:top>
                    <v-text-field prepend-inner-icon="search" v-model="search" label="Шукати у знайденому" class="mx-4"></v-text-field>
                </template>
                <template v-slot:item.rsn="{ item }">
                    <div v-if="item.rsn && item.rsn.split(',').length == 1">{{item.rsn}}</div>
                    <div @click="openSn(item.rsn)" style="cursor: pointer" class="link--text text-decoration-underline" v-if="item.rsn && item.rsn.split(',').length > 1">Показати SN ({{item.rsn && item.rsn.split(',').length}})</div>
                </template>
                <template v-slot:item.date="{ item }">
                    {{toDate(item.date)}}
                </template>
                <template v-slot:item.price_doc="{ item }">
                    {{format(item.price_doc)}}
                </template>
                <template v-slot:item.sum_doc="{ item }">
                    {{format(item.sum_doc)}}
                </template>
                <template v-slot:item.name="{ item }">
                    <v-menu max-width="200" offset-y open-on-hover>
                        <template v-slot:activator="{ on }">
                            <span v-on="on" style="cursor: pointer">{{item.name}}</span>
                        </template>
                        <v-img height="200" width="200" :src="item.img"></v-img>
                    </v-menu>
                </template>

                <template v-slot:no-data>
                    Немає данних
                </template>
                <template v-slot:loading>
                    Зачекайте...
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td class="sum "><b>Підсумок:</b></td>
                        <td class="sum text-end" :colspan="filteredHeaders.length - 1">
                            {{format(sum)}} ₴
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <AlertCompanyBlock />
        </template>
    </v-card>

    <v-dialog v-model="dialog" max-width="450">
        <v-card>
            <v-card-title class="headline">
                Перелік серійних номерів товару
            </v-card-title>

            <v-card-text class="pl-12">
                <div v-for="n in rsn.split(',')">{{n}}</div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                    Закрити
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters

} from 'vuex'
import {
    connect
} from "../ReportSocket"
import {
    mdiMicrosoftExcel
} from '@mdi/js'

import AlertCompanyBlock from "./AlertCompanyBlock.vue"

export default {
    components: {
        AlertCompanyBlock
    },
    props: {},
    data() {
        return {
            exclelIcon: mdiMicrosoftExcel,
            xlsLoading: false,
            show: false,
            confirmedFalse: false,
            contractorsFalse: false,
            menu1: false,
            menu2: false,
            sellDateTo: null,
            sellDateFrom: null,
            groupBySn: false,
            search: '',
            query: '',
            category: '',
            page: 1,
            pageCount: 0,
            totalPagesPagination: 7,
            contractor: {},
            dialog: false,
            rsn: '',
            closed: true,
            options: {
                page: 1,
                itemsPerPage: 40
            },
            footerProps: {
                'items-per-page-options': [20, 40, 80, 100],
                'items-per-page-text': 'на стор.'
            },
            mime: {
                xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                xml: "application/xml"
            },
            headers: [{
                    text: 'Дата',
                    align: 'center',
                    value: 'date',
                    sortable: true,
                    width: '7%',
                },
                {
                    text: 'Документ',
                    align: 'center',
                    value: 'document',
                    sortable: true,
                    width: '8%',
                    hiddenXs: true
                },
                {
                    text: 'Код',
                    align: 'center',
                    value: 'typhoon_id',
                    sortable: true,
                    width: '5%',
                    hiddenXs: true
                },
                {
                    text: 'Артикул',
                    align: 'center',
                    value: 'artikul',
                    sortable: true,
                    width: '10%',
                    hiddenXs: true,
                    hiddenSm: true
                },
                {
                    text: 'Бренд',
                    align: 'center',
                    value: 'brand_name',
                    sortable: true,
                    width: '10%',
                    hiddenXs: true,
                    hiddenSm: true
                },
                {
                    text: 'Товар',
                    align: 'left',
                    value: 'name',
                    sortable: true,
                    width: '20%',
                },
                {
                    text: 'SN',
                    align: 'center',
                    value: 'rsn',
                    sortable: true,
                    width: '10%',
                    hiddenXs: true,
                    hiddenSm: true
                },
                {
                    text: 'К-ть',
                    align: 'center',
                    value: 'qty',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Ціна, ₴',
                    align: 'right',
                    value: 'price_doc',
                    sortable: true,
                    width: '10%',
                },
                {
                    text: 'Сума, ₴',
                    align: 'right',
                    value: 'sum_doc',
                    sortable: true,
                    width: '10%',
                    hiddenXs: true,
                    hiddenSm: true
                },
            ],
        }
    },
    methods: {
        ...mapActions(['touch', 'getContactInfo', 'getClientContractors', 'setSnackbar', 'salesGoodsList', 'getCategories']),
        loadList() {
            this.options.page = 1
            this.touch()
                .then(() => {
                    this.salesGoodsList({
                        sellDateTo: this.sellDateTo,
                        sellDateFrom: this.sellDateFrom,
                        groupBySn: this.groupBySn ? 1 : 0,
                        contractorId: Object.keys(this.contractor).filter(el => this.contractor[el] === true).join(','),
                        category: this.category,
                        query: this.query
                    })
                })
        },
        roundTo(v, n = 2) {
            return parseFloat(v).toLocaleString('ua-UA')
            // return parseFloat(v).toLocaleString('ua-UA', {
            //     style: 'currency',
            //     currency: 'UAH'
            // })
        },
        format(v) {
            if (v) {
                return Number(String(v).replace(/\s+/g, '')).toLocaleString('ru-RU')
            } else {
                return 0
            }
        },
        toDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        setContractors() {
            for (let c of this.contractors) {
                this.$set(this.contractor, c.typhoon_id, true)
            }
        },
        clear() {
            this.sellDateTo = null
            this.sellDateFrom = null
            this.$store.commit('setGoodsList', [])
            this.$store.commit('setGoodsTotal', 0)
            this.search = ''
            this.category = ''
            this.query = ''
        },
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        openSn(sn) {
            this.dialog = true
            this.rsn = sn
        },
        async connectSocket() {
            try {
                const {
                    se,
                    socket
                } = await connect()
                this.loaded = false
                this.xlsLoading = false
                this.se = se
                this.socket = socket

                this.se.$on("message", (msg) => {
                    const date = new Date().toISOString()
                    const filename = `sales-goods-list.xlsx`
                    this.fileName = filename
                    this.saveData(msg, filename);
                    this.loaded = false
                    this.xlsLoading = false
                })
                this.se.$on("closed", () => {
                    setTimeout(() => {
                        this.closed = true
                        console.log("Websocket closed")
                    }, 5000);
                })
                this.se.$on("error", (error) => {
                    console.log("Не вдалося встановити з'єднання з сервером")
                    //this.setSnackbar(['error', "Не вдалося встановити з'єднання з сервером"])
                })
                this.se.$on("opened", () => {
                    this.closed = false
                    this.xlsLoading = false
                })
            } catch (error) {
                console.error(error)
                this.xlsLoading = false
            }
        },
        async createXlsx() {
            if (this.closed) {
                try {
                    this.xlsLoading = true
                    await this.connectSocket()
                    this.closed = false
                } catch (error) {
                    console.error(error)
                    this.xlsLoading = false
                }
            }
            if (this.socket.readyState == 1) {

                window.URL.revokeObjectURL(this.fileBlobURL); //очистить память от ранее принятого файла
                this.fileBlobURL = ''
                this.loaded = true
                this.xlsLoading = true
                const params = (new URL(document.location)).searchParams
                const token = this.getLocalStorage('authToken')
                this.se.send(JSON.stringify({
                    fileName: 'sales-goods-list.xlsx',
                    authToken: token,
                    type: 'sales-goods-list',
                    sellDateTo: this.sellDateTo,
                    sellDateFrom: this.sellDateFrom,
                    groupBySn: this.groupBySn ? 1 : 0,
                    contractorId: Object.keys(this.contractor).filter(el => this.contractor[el] === true).join(','),
                    category: this.category,
                    query: this.query
                }))
            } else {
                this.xlsLoading = false
                this.loaded = false
                this.setSnackbar(['error', "Не вдалося встановити з'єднання з сервером WS"])
            }
        },
        saveData(data, fileName, format) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const blob = new Blob([data], {
                type: this.mime[this.format]
            })
            let url = window.URL.createObjectURL(blob);
            this.fileBlobURL = a.href = url;
            a.download = fileName;
            a.click();
        },

    },
    watch: {},
    created() {
        this.clear()
        this.getContactInfo()
            .then(data => {
                if (data.groups && data.groups.cl_sales && !data.isSellToEmployee) {
                    this.show = true
                    if (data.isConfirmed) {
                        this.getClientContractors()
                            .then(d => {
                                if (!d[0]) {
                                    this.contractorsFalse = true
                                } else {
                                    this.setContractors()
                                }
                            })
                        this.getCategories()
                    } else {
                        this.confirmedFalse = true
                    }
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))
    },
    computed: {
        list() {
            return this.$store.state.sales.listGoods
        },
        totalItems() {
            return this.$store.state.sales.totalGoods
        },
        listLoading() {
            return this.$store.state.sales.loading
        },
        contractors() {
            return this.$store.state.sales.contractors
        },
        formatedSellDateFrom() {
            return this.toDate(this.sellDateFrom)
        },
        formatedSellDateTo() {
            return this.toDate(this.sellDateTo)
        },
        filteredHeaders() {
            return this.headers.filter(h => {
                return !h.hidden ? (this.$vuetify.breakpoint.xs ? !h.hiddenXs : (this.$vuetify.breakpoint.smAndDown ? !h.hiddenSm : true)) : false
            })
        },
        categories() {
            return this.$store.state.sales.categories
        },
        sum() {
            let sum = 0
            this.list.map(el => sum = sum + Number(el.sum_doc))
            return sum
        }
    },
}
</script>

<style>
.v-list-item__title {
    word-break: normal;
    /* maybe !important  */
    white-space: normal;
}

.v-data-table.products tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #E3F2FD !important;
}

.v-data-table.products tbody td {
    font-size: 0.75rem !important;
    font-weight: 400;
    letter-spacing: 0.0333333333em !important;
    line-height: 1.25rem;
    font-family: "Roboto", sans-serif !important;
    font-weight: 300 !important;
}

@media (max-width: 512px) {
    .v-data-table.products>.v-data-table__wrapper td {
        padding: 0 2px;
    }
}
</style>
